import { render, staticRenderFns } from "./FriendItem.vue?vue&type=template&id=11bb36bb"
import script from "./FriendItem.vue?vue&type=script&lang=js"
export * from "./FriendItem.vue?vue&type=script&lang=js"
import style0 from "./FriendItem.vue?vue&type=style&index=0&id=11bb36bb&prod&scope=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports